@import '../assets/colors';

.delivery-status {
  font-size: 1rem;
  padding: 0.25rem 0;

  &--success {
    color: $green-primary;
  }

  &--error {
    color: $alert;
  }
}
