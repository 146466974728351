@import '../../media';
@import '../../assets/_colors';

.place-orders {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @include _768 {
    padding: 50px;
  }

  .medium-divider-wrapper {
    margin-left: -20px;
    margin-right: -20px;

    @include _768 {
      margin-left: -50px;
      margin-right: -50px;
    }
  }

  &__alert {
    color: $green-dark;
    font-size: 1rem;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
  }

  &__alert-icon {
    margin-right: 10px;
  }
}

.place-order__delivery {
  margin-top: 10px;
  margin-bottom: 50px;
}

.place-order__delivery-label {
  position: relative;
  margin: 0 !important;
  padding-left: 41px;
  color: $green-dark;

  &:not(:last-child) {
    margin-bottom: 20px !important;
  }
}

.place-order__delivery-radio {
  position: absolute !important;
  left: -10px;
}

.place-orders__dropdown {
  margin-bottom: 40px;
}

.coupon__title,
.bonus-payment__title {
  font-size: 1.143rem;
  margin-bottom: 25px;
  color: $green-dark;
}

.coupon__container {
  display: flex;
  margin-bottom: 40px;
  position: relative;
}

.coupon__input {
  width: 133px;
  margin-right: 5px;
  padding-bottom: 0;
}

.coupon__btn {
  border-radius: 23px;
  padding: 8px 13px;
  &_remove {
    background: $orange;
  }
  &:disabled,
  &[disabled] {
    background-color: transparent;
    border: 1px solid $gray-form;
    color: $medium-gray;
    cursor: default;
  }
}

.place-order__bonus-payment {
  margin-bottom: 40px;
}

.bonus-payment__text-field {
  margin-bottom: 30px;
  @include _768 {
    margin-bottom: 15px;
  }
}

.bonus-payment__info-text {
  margin-bottom: 5px;
  font-size: 0.8571rem;
  color: $medium-gray;

  span {
    font-weight: 700;
    color: $green-primary;
  }
}

.price-btn-wrapper {
  @include _768 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.price-button-wrapper__price {
  margin-bottom: 20px;

  @include _768 {
    margin-bottom: 0;
  }
}

.price-button-wrapper__title {
  display: inline-block;
  margin-right: 10px;
  font-size: 1.143rem;
  font-weight: 700;
  color: $green-dark;
}

.price-button-wrapper__value {
  display: inline-block;
  font-size: 1.714rem;
  line-height: 1;
  font-weight: 700;
  color: $green-dark;

  .rub-sign {
    font-size: 1.714rem;
  }
}

.price-btn-wrapper__btn {
  width: 100%;
  padding: 15px 20px;
  border-radius: 23px;

  @include _768 {
    width: auto;
    padding: 15px 35px;
  }
}

.place-order__divider {
  margin-bottom: 40px;
}

.place-order__checkbox {
  margin-bottom: 40px;
}

.place-order__comment-wrapper {
  margin-bottom: 40px;
  position: relative;
}

.place-order__comment-field {
  height: fit-content;
  resize: none;
  padding: 0 30px 20px 0;
}

.place-order__change {
  margin-bottom: 40px;
}

.place-order__change-title {
  color: $green-dark;
  font-size: 1.143rem;
  margin-bottom: 25px;
}

.place-order__map-street {
  width: 100%;
}

.place-order__last-addresses-icon {
  display: inline-block;
  height: 18px;
  width: 19px;
  background-image: url('../../assets/icons/orders/recent_adress.svg');
}

.text-field--error {
  position: relative;

  span {
    display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.place-order__label {
  position: relative;
  display: block;
  margin-bottom: 17px;
  padding-top: 2px;

  &__description {
    display: block;
    margin-bottom: 18px;
  }

  &__error {
    display: block;
  }

  .place-order__phone-field {
    padding-right: 0;
    text-align: center;
    color: $green-dark;
  }
}

.place-order__submit-btn-container {
  display: flex;
  flex-direction: row;
  &_small {
    @include _768 {
      width: 50%;
    }
  }
}
